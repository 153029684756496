<template>
	<router-view></router-view>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class Main extends Vue {}
</script>

<style>
html,
body {
	padding: 0;
	margin: 0;
	height: 100%;
}

html {
	font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
	font-size: 62.5%;
}
</style>
