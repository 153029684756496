<template>
	<div class="embed">
		<display-renderer :display-id="displayId" :orientation-override="orientationOverride" :style="wrapperStyle" />
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { DisplayOrientationEnum } from "@scrinz/dtos";
import DisplayRenderer from "@/components/DisplayRenderer.vue";

@Component({
	components: { DisplayRenderer },
})
export default class Display extends Vue {
	@Prop({ required: true })
	displayId!: string;

	orientationOverride: DisplayOrientationEnum | null = null;
	scalePercent = 100;

	scaleWidth = 1920;
	scaleHeight = 1080;

	get scale() {
		return this.scalePercent / 100;
	}

	get wrapperStyle() {
		return {
			transform: `scale(${this.scale})`,
			transformOrigin: "top left",
		};
	}

	mounted() {
		window.addEventListener("resize", this.onResize);
		this.onResize();
	}

	onResize() {
		const width = window.innerWidth;
		const height = window.innerHeight;

		const scaleWidth = this.scaleWidth;
		const scaleHeight = this.scaleHeight;

		const scale = Math.min(width / scaleWidth, height / scaleHeight);

		this.scalePercent = scale * 100;
	}
}
</script>
