<template>
	<div class="dev-tools" v-if="showDevTools">
		<p>
			<label>Version:</label>
			<input disabled value="..." />
		</p>

		<p>
			<label>Display ID:</label>
			<input v-model="displayId" disabled />
		</p>

		<p>
			<label>API key:</label>
			<input v-model="apiKey" disabled />
		</p>

		<p>
			<label>Set orientation: </label>

			<select v-model="orientation">
				<option :value="null">Use remote configuration</option>
				<option :value="orientations.Horizontal">Horizontal</option>
				<option :value="orientations.Vertical">Vertical</option>
			</select>
		</p>

		<p>
			<button @click="reload">Reload</button>
		</p>

		<p>
			<button @click="uninstall" v-if="!uninstallVerified">Uninstall</button>
			<button @click="uninstall" v-if="uninstallVerified">Click again to verify uninstall</button>
		</p>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { DisplayOrientationEnum } from "@scrinz/dtos";

import reload from "@/reload";

@Component
export default class DevTools extends Vue {
	orientations = DisplayOrientationEnum;

	@Getter displayId!: string | null;
	@Getter apiKey!: string | null;

	showDevTools = false;
	showDevToolsKeyCombo: number[] = [];
	uninstallVerified = false;

	get orientation(): DisplayOrientationEnum {
		return this.$store.getters.orientation;
	}

	set orientation(orientation: DisplayOrientationEnum) {
		void this.$store.dispatch("setOrientation", orientation);
	}

	async created() {
		document.addEventListener("keydown", (e) => {
			this.showDevToolsKeyCombo.push(e.keyCode);

			if (this.showDevToolsKeyCombo.length > 4) {
				this.showDevToolsKeyCombo.splice(0, this.showDevToolsKeyCombo.length - 4);
			}

			if (this.showDevToolsKeyCombo.join(",") === "37,37,39,37") {
				this.showDevTools = !this.showDevTools;
			}
		});
	}

	async reload() {
		await reload.reload();
	}

	async uninstall() {
		if (!this.uninstallVerified) {
			this.uninstallVerified = true;

			return;
		}

		await this.$store.dispatch("unsetInstallation");
		this.$router.push("/setup");
	}
}
</script>

<style lang="scss" scoped>
.dev-tools {
	background: rgba(255, 255, 255, 0.97);
	box-shadow: 0 0 3px black;
	position: absolute;
	top: 0;
	left: 0;
	width: 360px;
	padding: 15px 30px;
	z-index: 10000;

	p {
		display: flex;
		justify-content: space-between;

		input,
		select,
		button {
			border: 1px solid rgba(0, 0, 0, 0.67);
			border-radius: 2px;
			box-sizing: border-box;
			background: white;
			min-width: 200px;
			padding: 2px 5px;
			text-align: center;
		}
	}
}
</style>
