import { GetterTree } from "vuex";

import { DisplayOrientationEnum } from "@scrinz/dtos";
import { RootState } from "../types";
import { InstallationState } from "./types";

export const getters: GetterTree<InstallationState, RootState> = {
	installed(state): boolean {
		return !!state.installation && !!state.installation.displayId;
	},

	apiKey(state): string | null {
		return state.installation && state.installation.apiKey;
	},

	displayId(state): string | null {
		return state.installation && state.installation.displayId;
	},

	orientation(state): DisplayOrientationEnum | null {
		return state.installation && state.installation.orientation;
	},
};
