<template>
	<div class="centered-card-layout">
		<div class="centered-card-layout--container">
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue";
	import { Component } from "vue-property-decorator";

	@Component
	export default class CenteredCardLayout extends Vue {}
</script>

<style lang="scss" scoped>
	.centered-card-layout {
		background: #1b1b1b;
		align-content: center;
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		width: 100%;

		&--container {
			background: white;
			box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
			max-height: 80%;
			max-width: 80%;
			overflow: auto;
		}
	}
</style>
