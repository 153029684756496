import { AxiosResponse } from "axios";
// import { IsEnum, IsString } from "class-validator";

import { DisplayOrientationEnum } from "@scrinz/dtos";

export class InstallationException extends Error {
	readonly data: any;
	readonly error: any;

	constructor(message: string, data: any, error?: any) {
		super(message);
		this.data = data;
		this.error = error;
	}
}

export const enum RegistrationExceptionCodes {
	DisplayNotFound,
	InvalidApiKey,
	HttpError,
	NetworkError,
	UnknownError,
}

export class RegistrationException extends Error {
	readonly name = "RegistrationException";
	readonly code: RegistrationExceptionCodes;
	readonly error: Error;
	readonly request!: any;
	readonly response!: any;
	readonly stack!: any;

	get message() {
		return this.response ? this.response.message : this.error.message;
	}

	constructor(code: RegistrationExceptionCodes, error: Error & { response?: AxiosResponse }, request?: any) {
		super();
		this.code = code;
		this.error = error;
		this.stack = error.stack;
		if (request) this.request = request;
		if (error.response) this.response = error.response.data;
	}
}

export class InstallationData {
	// @IsString()
	apiKey!: string;

	// @IsString()
	displayId!: string;

	// @IsEnum(DisplayOrientationEnum)
	orientation!: DisplayOrientationEnum;
}

export interface InstallationState {
	installation: InstallationData | null;
}
