<template>
	<div>
		<table class="debug-info">
			<thead>
				<tr>
					<th>Debug info</th>
					<th>Value</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(value, key) of debugInfo"
					:key="key"
				>
					<td>{{ key }}</td>
					<td><code>{{ value }}</code></td>
				</tr>
			</tbody>
		</table>

		<div class="size-test">
			<div class="size-test--px540">540px</div>
			<div class="size-test--percent50">50%</div>
			<div class="size-test--px960">960px</div>
		</div>

		<div class="font-scale-test">
			<div class="font-scale-test--test-text">
				Labore sit duis excepteur sint culpa.
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class DebugMainView extends Vue {
	debugInfo = {
		"window.devicePixelRatio": window.devicePixelRatio,
	};
}
</script>

<style lang="scss" scoped>
.debug-info {
	th {
		text-align: left;
	}
}

.size-test {
	width: 100%;

	> * {
		height: 60px;
		background: lightblue;
	}

	&--percent {
		&50 {
			width: 50%;
		}
	}

	&--px {
		&960 {
			width: 960px;
		}

		&540 {
			width: 540px;
		}
	}
}
</style>
