<template>
	<div class="absolute-layout" :class="styleClasses">
		<div class="section notes background-white"><slot name="notes" /></div>
		<div class="section templates background-white"><slot name="templates" /></div>
		<div class="section adsSmall background-black"><slot name="ads-small" /></div>
		<div class="section modules background-black"><slot name="modules" /></div>
		<div class="section adsLarge background-black"><slot name="ads-large" /></div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { DisplayOrientationEnum } from "@scrinz/dtos";

@Component({})
export default class AbsoluteLayout extends Vue {
	@Prop({ required: true, type: [String] })
	orientation!: DisplayOrientationEnum;

	@Prop({ default: false })
	demo!: boolean;

	@Prop({ default: false })
	hideModules!: boolean;

	get styleClasses() {
		return {
			colors: this.demo,
			"hide-modules": this.hideModules,
			horizontal: this.orientation === DisplayOrientationEnum.Horizontal,
			vertical: this.orientation === DisplayOrientationEnum.Vertical,
		};
	}
}
</script>

<style lang="scss" scoped>
.absolute-layout {
	position: relative;
	overflow: hidden;

	> .section {
		position: absolute;
		background: rgba(0, 0, 0, 0.3);
		overflow: hidden;
	}

	.background-white {
		background: white;
	}

	.background-black {
		background: #1b1b1b;
	}

	&.colors {
		.notes {
			background-color: #03a9f4;
		}
		.templates {
			background-color: #00bcd4;
		}
		.adsSmall {
			background-color: #009688;
		}
		.modules {
			background-color: #cddc39;
		}
		.adsLarge {
			background-color: #ff9800;
		}
	}

	.adsSmall {
		width: 480px;
		height: 175px;
	}

	.adsLarge {
		width: 590px;
		height: 630px;
	}

	.modules {
		width: 590px;
		height: 440px;
	}

	.notes {
		width: 830px;
		height: 1080px;
	}

	.templates {
		width: 480px;
		height: 895px;
	}

	&.horizontal {
		width: 1920px;
		height: 1080px;

		.templates {
			top: 0;
			left: 0;
		}

		.adsSmall {
			top: 905px;
			left: 0;
		}

		.notes {
			top: 0;
			left: 490px;
		}

		.modules {
			top: 0;
			left: 1330px;
		}

		.adsLarge {
			top: 450px;
			left: 1330px;
		}
	}

	&.vertical {
		width: 1080px;
		height: 1920px;

		.templates {
			top: 0;
			left: 0;
		}

		.adsSmall {
			top: 905px;
			left: 0;
		}

		.modules {
			top: 0;
			left: 490px;
		}

		.adsLarge {
			top: 450px;
			left: 490px;
		}

		.notes {
			top: 1090px;
			left: 0;
			width: 1080px;
			height: 830px;
		}
	}

	&.hide-modules {
		.modules {
			display: none;
		}

		.adsLarge {
			top: 0;
			height: 1080px;
		}
	}
}
</style>
