<template>
	<div class="preview-scroller" ref="scroller">
		<div class="preview-scroller--wrapper" :style="wrapperStyle">
			<div class="preview-scroller--scale" :style="scaleStyle">
				<div class="preview-scroller--content">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { DisplayOrientationEnum } from "@scrinz/dtos";
import { orientationSizeStyle } from "@/utils";

@Component
export default class PreviewScroller extends Vue {
	@Prop({ required: true, type: [Number, String] })
	scalePercentage!: number | string;

	@Prop({ default: null, type: [Number] })
	orientationOverride!: DisplayOrientationEnum | null;

	get orientation() {
		if (this.orientationOverride !== null) return this.orientationOverride;

		return this.$store.getters.orientation || DisplayOrientationEnum.Horizontal;
	}

	get scale() {
		let percentage = this.scalePercentage;

		if (typeof percentage === "string") {
			percentage = parseInt(percentage, 10);
		}

		return percentage;
	}

	get wrapperStyle() {
		return {
			...orientationSizeStyle(this.orientation),
		};
	}

	get scaleStyle() {
		return {
			transform: `scale(${this.scale / 100})`,
		};
	}

	async mounted() {
		const scroller = this.$refs["scroller"] as Element;

		const heightDiff = scroller.scrollHeight - scroller.clientHeight;
		const widthDiff = scroller.scrollWidth - scroller.clientWidth;

		scroller.scrollTop = heightDiff / 2;
		scroller.scrollLeft = widthDiff / 2;
	}
}
</script>

<style lang="scss" scoped>
.preview-scroller {
	width: 100%;
	height: 100%;
	overflow: auto;

	&--content {
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	}
}
</style>
