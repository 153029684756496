import http from "@/http";

export class ContentService {
	static async getContent(contentSlot: "note" | "template" | "adsSmall" | "adsLarge", displayId: string) {
		try {
			const res = await http.get(`display/${displayId}/${contentSlot}`);
			if (res && res.data) return res.data;
			else throw new Error("No data returned");
		} catch (err) {
			// TODO: Retry or something
			throw err;
		}
	}
}
