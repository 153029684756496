import http from "@/http";

export class WeatherService {
	static async getWeather(city: string) {
		try {
			const res = await http.get(`weather/weather`, { params: { city } });
			if (res && res.data) return res.data;
			else throw new Error("No data returned");
		} catch (err) {
			// Do nothing yet.
			throw err;
		}
	}
}
