<template>
	<div :class="classes">
		<div ref="wrapper" class="sd-note-board-notes__wrapper">
			<div class="sd-note-board-notes__container sd-note-board-notes__container--sticky">
				<template v-for="(note, index) of stickyNotes">
					<sd-note
						ref="stickyNotes"
						class="sd-note-board-notes__note"
						v-if="note._show"
						:key="note.id"
						:date="note.start"
						:icon="note.icon"
						:icon-background-color="note.iconColor"
						:html-body="note.text"
						:qr-content="note.qrContent"
						:style="{
							'z-index': zIndexForIndex(index),
						}"
					/>
				</template>
			</div>

			<transition-group
				tag="div"
				ref="container"
				name="sd-note-board-notes__note-"
				class="sd-note-board-notes__container sd-note-board-notes__container--regular"
			>
				<template v-for="(note, index) of regularNotes">
					<sd-note
						ref="notes"
						class="sd-note-board-notes__note"
						v-if="note._show"
						:key="note.id"
						:date="note.start"
						:icon="note.icon"
						:icon-background-color="note.iconColor"
						:html-body="note.text"
						:qr-content="note.qrContent"
						:style="{
							'z-index': zIndexForIndex(index),
						}"
					/>
				</template>
			</transition-group>
		</div>

		<div class="sd-note-board-notes__shadow" :style="shadowStyles"></div>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import uniqBy from "lodash/uniqBy";

import { DisplayOrientationEnum } from "@scrinz/dtos";
import { SdNote } from "@scrinz/components";

import { ContentService } from "@/services/ContentService";

function hexToRgb(hex: string) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}

@Component({
	name: "sd-note-board-notes",
	components: {
		SdNote,
	},
})
export default class SdNoteBoardNotes extends Vue {
	@Prop({ required: true })
	displayId!: string;

	@Prop({ required: true })
	manifest!: any;

	@Prop({ default: DisplayOrientationEnum.Horizontal, type: [String] })
	orientation!: DisplayOrientationEnum;

	rotationTimeout!: any;
	updateTimeoutTiming = 20000;
	updateTimeout!: any;

	internalNotes: any[] = [];

	stickyNotes: any[] = [];
	regularNotes: any[] = [];

	get classes() {
		return {
			"sd-note-board-notes": true,
			"sd-note-board-notes--horizontal": this.orientation === DisplayOrientationEnum.Horizontal,
			"sd-note-board-notes--vertical": this.orientation === DisplayOrientationEnum.Vertical,
		};
	}

	get shadowStyles() {
		const styles: any = {};

		const bgColor =
			this.manifest.display.config.noteBoardBackgroundColor ??
			this.manifest.organization.config.noteBoardBackgroundColor;
		const rgb = hexToRgb(bgColor);

		if (rgb) {
			styles.background = `linear-gradient(
				to top,
				rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1) 0%,
				rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0) 100%
			)`;
		}

		return styles;
	}

	getWrapperElement() {
		return this.$refs.wrapper as HTMLElement;
	}

	getNotesElements() {
		return this.$refs.notes as Vue[];
	}

	getTotalVerticalSpace() {
		const container = (this.$refs.container as Vue)?.$el;
		return [container.clientHeight + 10, container.clientHeight + 10]
			.splice(0, this.orientation === DisplayOrientationEnum.Vertical ? 2 : 1);
	}

	getNotesHeights() {
		const notes = this.getNotesElements();
		if (!notes) return [];
		return notes.map((n) => (n.$el.clientHeight as number) + 10);
	}

	isOverflowing() {
		const space = this.getTotalVerticalSpace();
		const heights = this.getNotesHeights();

		while (heights.length > 0) {
			if (space.length === 0) return true;

			if (space[0] - heights[0] < 0) {
				space.shift();
				continue;
			}

			space[0] = space[0] - heights[0];
			heights.shift();
		}

		return false;
	}

	zIndexForIndex(index: number) {
		if (!this.internalNotes) return 0;
		return this.internalNotes.length - index;
	}

	created() {
		this.fetchNotes();
	}

	async fetchNotes() {
		const now = new Date().valueOf();
		this.internalNotes = await ContentService.getContent("note", this.displayId)
			.then((notes: any[]) => {
				// added to make sure no duplicate notes are shown
				return uniqBy(notes, "id");
			})
			.then((notes: any[]) => {
				// hide ended and future notes
				return notes.map((note: any) => {
					note.start = new Date(note.start);
					if (note.end) note.end = new Date(note.end);
					note._show = true;
					if (note.start.valueOf() > now) note._show = false;
					if (note.end && note.end.valueOf() < now) note._show = false;
					return note;
				});
			})
			.then((notes: any[]) => {
				// sorts notes by start date
				return notes.sort((a, b) => {
					const av = a.start.valueOf();
					const bv = b.start.valueOf();
					if (av > bv) return -1;
					if (av < bv) return 1;
					if (av === bv) {
						if (a.id > b.id) return -1;
						if (a.id < b.id) return 1;
					}
					return 0;
				});
			});

		this.regularNotes = this.internalNotes.filter((note) => !!note._show && note.sticky !== true);
		this.stickyNotes = this.internalNotes.filter((note) => !!note._show && note.sticky === true);

		this.resetUpdateTimeout();
	}

	resetUpdateTimeout() {
		if (this.updateTimeout) clearTimeout(this.updateTimeout);
		this.updateTimeout = setTimeout(this.update.bind(this), this.updateTimeoutTiming);
	}

	async update() {
		this.setShowState();

		if (this.rotationTimeout) clearTimeout(this.rotationTimeout);
		this.rotationTimeout = setTimeout(this.rotate.bind(this), 2000);

		this.resetUpdateTimeout();
	}

	async rotate() {
		await this.$nextTick();
		if (!this.isOverflowing()) return;
		const regularFirst = this.regularNotes.shift();
		await this.$nextTick();
		this.regularNotes.push(regularFirst);
		await this.$nextTick();
	}

	setShowState() {
		const now = new Date().valueOf();

		const hideIfEnded = (note: any, index: number, arr: any[]) => {
			if (note._show === true && note.end && note.end.valueOf() < now) {
				note._show = false;
				arr.splice(index, 1);
			}
		};

		this.regularNotes.forEach(hideIfEnded, this.regularNotes);
		this.stickyNotes.forEach(hideIfEnded, this.stickyNotes);

		this.internalNotes.forEach((note) => {
			if (note._show === false && note.start.valueOf() < now) {
				if (note.end && note.end.valueOf() < now) return;
				note._show = true;
				if (note.sticky === true) this.stickyNotes.push(note);
				else this.regularNotes.push(note);
			}
		});
	}
}
</script>

<style lang="scss">
.sd-note-board-notes {
	$nbn: &;

	overflow: hidden;
	position: relative;

	&__wrapper {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	&__container {
		margin: 0 -1rem;

		&--regular {
			flex: 1;
			overflow: hidden;
		}
	}

	&__note {
		transition: all 1s;
		margin-bottom: 1rem;
		margin: 0 1rem 1rem;

		&--enter,
		&--leave-to {
			opacity: 0;
			// transform: translateY(30px);
		}
	}

	&--horizontal {
		#{$nbn}__note {
			width: 73rem;
		}

		#{$nbn}__shadow {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: -1rem;
			right: -1rem;
			height: 20px;

			background: linear-gradient(to top, rgba(224, 214, 208, 1) 0%, rgba(224, 214, 208, 0) 100%);
		}
	}

	&--vertical {
		#{$nbn}__container {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			overflow: hidden;

			&--sticky {
				align-items: flex-start;
				flex-direction: row;
			}
		}

		#{$nbn}__note {
			width: 48rem;
		}
	}
}
</style>
