<template>
	<div class="sd-note-board-info">
		<div class="sd-note-board-info__text">
			<h1 v-if="manifest.organization">
				{{ manifest.organization.name }}
			</h1>

			<p v-if="manifest.codeWord && manifest.showCodeWord">
				Melding på tavla? Send SMS med kodeord
				<strong>{{ manifest.codeWord }}</strong> "din melding" til <strong>{{ smsNumber }}</strong
				>.
			</p>

			<p v-else>
				<!-- Tavla er ikke satt opp for innkommende SMS. -->
			</p>

			<p v-if="manifest.displayInformation">
				{{ manifest.displayInformation }}
			</p>
		</div>

		<!-- <sd-qr-code :qr-content="previewLink" /> -->

		<p class="sd-note-board-info__logo"><img src="../../assets/logo.svg" /></p>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { encodeHashid } from "@scrinz/hashids";
import { SdQrCode } from "@scrinz/components";

@Component({
	name: "sd-note-board-info",
	components: {
		SdQrCode,
	},
})
export default class SdNoteBoardInfo extends Vue {
	@Prop({ required: true })
	manifest!: any;

	@Prop({})
	smsNumber!: string;

	get previewLink() {
		const hashId = encodeHashid("display", this.manifest.entity.id);

		return `${window.location.origin}/preview/${hashId}`;
	}

	mounted() {
		this.$emit("mounted");
	}
}
</script>

<style lang="scss">
.sd-note-board-info {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	&__text {
		color: #7d7574;
		font-size: 13px;

		h1 {
			font-size: 1.8em;
			text-transform: uppercase;
		}

		p {
			margin: 0;
			font-size: 1.5rem;
		}
	}

	&__logo {
		transform: translate(0, 13px);
		margin: 0;

		img {
			display: inline-block;
			width: 120px;
		}
	}
}
</style>
