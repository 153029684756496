<template>
	<div>
		<display-renderer v-if="installed && displayId" :display-id="displayId" :orientation-override="orientation" />

		<dev-tools />
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import moment from "moment";

import { DisplayOrientationEnum } from "@scrinz/dtos";

import reload from "@/reload";
import socket from "@/socket";

import DevTools from "@/components/DevTools.vue";
import DisplayRenderer from "@/components/DisplayRenderer.vue";

function randomIntFromInterval(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1) + min); // tslint:disable-line
}

@Component({
	components: { DevTools, DisplayRenderer },
})
export default class Display extends Vue {
	@Getter installed!: boolean;
	@Getter displayId!: string;
	@Getter orientation!: DisplayOrientationEnum;

	async created() {
		socket.connect(this.displayId);

		// Set an auto-refresh at 03:00 every night
		// tslint:disable:no-magic-numbers
		const minutesToRefresh = randomIntFromInterval(50, 70);
		const msToRefresh = minutesToRefresh * 60 * 1000;

		// Refresh in about an hour, or at 03:00 or 15:00, whichever comes first.
		this.setRefreshTimeout(msToRefresh);
		this.setScheduledRefresh(3);
		this.setScheduledRefresh(15);
		// tslint:enable:no-magic-numbers
	}

	setRefreshTimeout(msFromNow: number) {
		setTimeout(async () => {
			await reload.reload();
		}, msFromNow);
	}

	setScheduledRefresh(refreshHour: number) {
		const now = moment();
		const then = now.clone().set("hour", refreshHour).set("minute", 0).set("second", 0);
		if (then.diff(now) < 0) {
			then.add(1, "days");
		}

		this.setRefreshTimeout(then.diff(now));
	}
}
</script>
