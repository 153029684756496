<template>
	<centered-card-layout>
		<div class="content setup" v-if="step === steps.Setup">
			<h1>Setup</h1>
			<p>This display has not been setup yet. Fill out the following information to register the display.</p>

			<form @submit="register">
				<p>
					<label>Display ID:</label>
					<input type="text" v-model="displayId" />
				</p>

				<p>
					<label>API key:</label>
					<input type="text" v-model="apiKey" />
				</p>

				<p>
					<label>ID/API key:</label>
					<input type="text" v-model="idApiKey" />
				</p>

				<p>
					<label>Orientation:</label>
					<select v-model="orientation">
						<option :value="null">Get from server</option>
						<option value="horizontal">Horizontal</option>
						<option value="vertical">Vertical</option>
					</select>
				</p>

				<p>
					<button type="submit" class="button">Register</button>
				</p>
			</form>
		</div>

		<div class="content processing" v-if="step === steps.Processing">Processing...</div>

		<div class="content success" v-if="step === steps.Success">
			<h1>Successfully set up!</h1>
			<p>This display have now been successfully registered.</p>
			<p>
				<router-link to="/" class="button">Go to display</router-link>
				<em class="redirect-counter">(Auto-redirect {{ redirectCountdownCounter }} seconds...)</em>
			</p>
		</div>

		<div class="content error" v-if="step === steps.Error">
			<h1>
				Error: <small>{{ getErrorTitle(error) }}</small>
			</h1>
			<p class="error-message">{{ getErrorMessage(error) }}</p>

			<div class="error-debug" v-if="error.request">
				<p>Request:</p>
				<pre>{{ error.request }}</pre>
			</div>

			<div class="error-debug" v-if="showErrorResponse(error)">
				<p>Response:</p>
				<pre>{{ error.response }}</pre>
			</div>

			<div class="error-debug" v-if="showErrorStackTrace(error)">
				<p>Stack trace:</p>
				<pre>{{ error.error.stack }}</pre>
			</div>

			<p>
				<a href="#" @click="goBackToSetup" class="button">Try again</a>
				<em class="redirect-counter">(Auto-redirect in {{ redirectCountdownCounter }} seconds...)</em>
			</p>
		</div>
	</centered-card-layout>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";

import { DisplayOrientationEnum } from "@scrinz/dtos";
import { RegistrationException, RegistrationExceptionCodes } from "@/store";
import CenteredCardLayout from "@/layouts/CenteredCard.vue";

enum SetupSteps {
	Setup,
	Processing,
	Success,
	Error,
}

@Component({
	components: { CenteredCardLayout },
})
export default class Setup extends Vue {
	steps = SetupSteps;

	displayId: string = "";
	apiKey: string = "";
	idApiKey: string = "";
	orientation: DisplayOrientationEnum | null = null;

	step: SetupSteps = SetupSteps.Setup;
	error: RegistrationException | undefined;
	redirectCountdownCounter: number = 0;
	redirectCountdownTimeout!: number | undefined;

	getErrorTitle(error: RegistrationException) {
		return error.code === RegistrationExceptionCodes.HttpError
			? `${error.response.statusCode} - ${error.response.error}`
			: error.code === RegistrationExceptionCodes.NetworkError
			? `No API connection`
			: error.message;
	}

	getErrorMessage(error: RegistrationException) {
		// tslint:disable:max-line-length
		switch (error.code) {
			case RegistrationExceptionCodes.DisplayNotFound:
				return "The provided display ID couldn't be found.";
			case RegistrationExceptionCodes.InvalidApiKey:
				return "The API key provided didn't match with given display ID. Ensure you've entered both correctly.";
			case RegistrationExceptionCodes.HttpError:
				return "An unexpected network error occured. Contact support and supply following information:";
			case RegistrationExceptionCodes.NetworkError:
				return "Couldn't make contact with the registration API. The server might be down, or your local network might not be connected to the Internet.";
			default:
				return "An unknown error occured. Contact support and supply following information to get a resolution.";
		}
		// tslint:enable:max-line-length
	}

	showErrorResponse(error: RegistrationException) {
		return (
			error.response &&
			[
				RegistrationExceptionCodes.DisplayNotFound,
				RegistrationExceptionCodes.InvalidApiKey,
				RegistrationExceptionCodes.HttpError,
				RegistrationExceptionCodes.NetworkError,
			].includes(error.code)
		);
	}

	showErrorStackTrace(error: RegistrationException) {
		return (
			(error.stack || (error.error && error.error.stack)) &&
			[
				RegistrationExceptionCodes.NetworkError,
				RegistrationExceptionCodes.HttpError,
				RegistrationExceptionCodes.UnknownError,
			].includes(error.code)
		);
	}

	async register(event: Event) {
		event.preventDefault();

		this.step = SetupSteps.Processing;
		this.error = undefined;

		let displayId = this.displayId;
		let apiKey = this.apiKey;

		if (this.idApiKey) {
			const parts = this.idApiKey.split(":");

			if (parts.length !== 2) {
				this.error = {
					code: RegistrationExceptionCodes.UnknownError,
					message: "Invalid ID/API key format.",
				};
				this.step = SetupSteps.Error;
				return;
			}

			displayId = parts[0];
			apiKey = parts[1];
		}

		const requestData = {
			apiKey,
			displayId,
			orientation: this.orientation,
		};

		try {
			const installation = await this.$store.dispatch("registerDisplay", requestData);

			if (!installation) {
				throw new Error("Failed to register display.");
			}

			this.redirectCountdown();
			this.step = SetupSteps.Success;
		} catch (err) {
			this.error = err as RegistrationException;

			// tslint:disable:no-magic-numbers ter-newline-after-var
			const countdownTimes: { [code: number]: number } = {};
			countdownTimes[RegistrationExceptionCodes.HttpError] = 30;
			countdownTimes[RegistrationExceptionCodes.UnknownError] = 30;
			// tslint:enable:no-magic-numbers ter-newline-after-var

			this.redirectCountdown(false, countdownTimes[err.code] || 5);
			this.step = SetupSteps.Error;
		}
	}

	goBackToSetup(event?: Event) {
		if (event) event.preventDefault();

		if (this.redirectCountdownTimeout) {
			clearTimeout(this.redirectCountdownTimeout);
		}

		this.redirectCountdownCounter = 0;
		this.step = SetupSteps.Setup;
	}

	redirectCountdown(success = true, initialSeconds = 3) {
		if (this.redirectCountdownCounter === 0) {
			this.redirectCountdownCounter = initialSeconds;
		} else {
			this.redirectCountdownCounter -= 1;
		}

		if (this.redirectCountdownCounter === 0) {
			if (success) this.$router.push("/");
			else this.goBackToSetup();

			return;
		}

		this.redirectCountdownTimeout = window.setTimeout(
			() => {
				this.redirectCountdown(success);
			},
			1000, // tslint:disable-line:no-magic-numbers
		);
	}
}
</script>

<style lang="scss" scoped>
.content {
	padding: 40px;
	width: 600px;
	box-sizing: border-box;

	> * {
		max-width: 520px;
		margin: 30px 0 0;
	}

	h1 {
		border-bottom: 3px solid rgba(0, 0, 0, 0.37);
		background: rgba(0, 0, 0, 0.17);
		box-sizing: border-box;
		margin: -40px -40px 0;
		max-width: none;
		padding: 20px 40px;
		width: 600px;

		small {
			font-size: inherit;
			font-weight: normal;
		}
	}

	p {
		font-size: 19px;
	}

	.button {
		background: white;
		border: 2px solid rgba(0, 0, 0, 0.67);
		color: black;
		cursor: pointer;
		display: inline-block;
		font-family: sans-serif;
		font-size: 1em;
		margin: 0 10px 0 0;
		padding: 5px 8px;
		text-decoration: none;
		transition: 0.2s ease all;

		&:hover,
		&:focus {
			background: rgba(0, 0, 0, 0.17);
			outline: none;
		}
	}

	.redirect-counter {
		font-size: 16px;
	}

	form {
		p {
			display: flex;
			align-items: center;
			margin: 12px 0 0;

			&:last-child {
				margin-top: 30px;
			}

			label {
				display: inline-block;
				margin: 0 10px 0 0;
				text-align: right;
				width: 100px;
			}

			input {
				font-family: monospace;
			}

			input,
			select {
				flex: 1;
				background: none;
				border: none;
				border-bottom: 2px solid grey;
				font-size: 15px;
				padding: 5px 16px;
				text-align: center;

				&:focus {
					border-color: rgba(200, 50, 50, 0.87);
					outline: none;
				}
			}
		}
	}

	&.success {
		h1 {
			border-color: green;
			background: rgba(0, 255, 0, 0.17);
		}
	}

	&.error {
		h1 {
			border-color: red;
			background: rgba(255, 0, 0, 0.17);
		}

		.error-debug {
			p,
			pre {
				margin: 0;
			}

			p {
				font-weight: 600;
				margin-bottom: 8px;
			}

			pre {
				background: rgba(0, 0, 0, 0.09);
				border: 1px solid darkgray;
				border-radius: 2px;
				box-sizing: border-box;
				max-width: 520px;
				overflow: auto;
				padding: 16px;
			}
		}
	}
}
</style>
