import io from "socket.io-client";
import { Observable } from "rxjs";

import { SOCKET_PATH } from "@/constants";
import reload from "@/reload";

export type Event = string;

class Socket {
	observables: { [topic: string]: Observable<Event> } = {};
	socket: SocketIOClient.Socket;

	constructor() {
		this.socket = io({
			path: SOCKET_PATH,
			autoConnect: false,
		});
	}

	connect(id: string) {
		this.socket.close();

		this.socket.once("connect", () => {
			this.socket.emit("register", id);
		});

		this.socket.on("reconnect", () => {
			this.socket.emit("register", id);
		});

		this.socket.once("registered", () => {});

		this.socket.on("update", async () => {
			await reload.reload();
		});

		this.socket.connect();
	}

	subscribe(topic: string, observer?: (val: Event) => void) {
		if (!this.observables[topic]) {
			this.observables[topic] = new Observable<Event>((subscribe) => {
				const next = () => {
					subscribe.next("yo");
				};

				// setInterval(next, 1000);
				next();
			});
		}

		return this.observables[topic].subscribe(observer);
	}
}

const socket = new Socket();

export default socket;
