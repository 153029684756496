<template>
	<div class="preview">
		<preview-scroller :scale-percentage="scalePercent" :orientation-override="orientationOverride">
			<display-renderer :display-id="displayId" :orientation-override="orientationOverride" />
		</preview-scroller>

		<preview-options :scale-percentage.sync="scalePercent" :orientation-override.sync="orientationOverride" />
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { DisplayOrientationEnum } from "@scrinz/dtos";
import DisplayRenderer from "@/components/DisplayRenderer.vue";
import PreviewOptions from "@/components/PreviewOptions.vue";
import PreviewScroller from "@/components/PreviewScroller.vue";

@Component({
	components: { DisplayRenderer, PreviewOptions, PreviewScroller },
})
export default class Display extends Vue {
	@Prop({ required: true })
	displayId!: string;

	orientationOverride: DisplayOrientationEnum | null = null;
	scalePercent = 70; // tslint:disable-line:no-magic-numbers

	get scale() {
		return this.scalePercent / 100;
	}

	get wrapperStyle() {
		return {
			transform: `translate(-50%, -50%) scale(${this.scale})`,
		};
	}
}
</script>

<style lang="scss" scoped>
.preview {
	background: rgba(100, 100, 0, 0.05);
	position: relative;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
}
</style>
