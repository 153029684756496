<template>
	<div class="display-renderer" :style="displayRendererStyles">
		<v-offline @detected-condition="onNetworkConditionDetected" />

		<absolute-layout :orientation="orientation" v-if="displayId && manifest">
			<sd-note-board
				slot="notes"
				:display-id="displayId"
				:manifest="manifest"
				:sms-number="smsNumber"
				:orientation="orientation"
			/>

			<sd-service-modules
				slot="modules"
				:show-clock="manifest.modules.clock"
				:show-transit-times="manifest.modules.transit"
				:show-weather="manifest.modules.weather"
				:transit-times="transitTimes"
				:weather="weather"
			/>

			<sd-slider
				slot="templates"
				v-if="contentLoaded.template"
				:assets-base-path="assetsBasePath"
				:slides="content.template"
				:fallback-slides="manifest.fallback.template"
				:fallback-text="bannerFallbackText"
				:show-fallback-text="true"
				:template-variables="bannerTemplateVariables"
			/>

			<sd-slider
				slot="ads-small"
				v-if="contentLoaded.adsSmall"
				:assets-base-path="assetsBasePath"
				:slides="content.adsSmall"
				:fallback-slides="manifest.fallback.adsSmall"
			/>

			<sd-slider
				slot="ads-large"
				v-if="contentLoaded.adsLarge"
				:assets-base-path="assetsBasePath"
				:slides="content.adsLarge"
				:fallback-slides="manifest.fallback.adsLarge"
			/>
		</absolute-layout>

		<centered-card-layout v-else-if="displayId">
			<div class="state state-loading" v-if="state === states.Loading">
				<h1>Loading</h1>
				<p class="loading"><loading-spinner /></p>
			</div>

			<div class="state state-offline" v-if="state === states.Offline">
				<h1>Offline</h1>
				<p>The device seems to be offline. Please reconnect in order to load display.</p>
			</div>

			<div class="state state-error" v-if="state === states.Error">
				<h1>Error rendering display</h1>
				<p>An unexpected error occured durion initiation of the display.</p>
				<p>Trying again in {{ errorRetryCountdown }} seconds.</p>
			</div>
		</centered-card-layout>

		<centered-card-layout v-else>
			<div class="state state-error" v-if="state === states.Error">
				<h1>Error</h1>
				<p>No display ID specified.</p>
			</div>
		</centered-card-layout>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import VOffline from "v-offline";

import { SdServiceModules, SdSlider } from "@scrinz/components";
import { DisplayOrientationEnum } from "@scrinz/dtos";

import { API_PATH } from "@/constants";
import http from "@/http";
import { orientationSizeStyle } from "@/utils";

import AbsoluteLayout from "@/layouts/AbsoluteLayout.vue";
import CenteredCardLayout from "@/layouts/CenteredCard.vue";

import SdNoteBoard from "@/components/SdNoteBoard";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { ContentService } from "@/services/ContentService";
import { TransitTimesService } from "@/services/TransitTimesService";
import { WeatherService } from "@/services/WeatherService";

type ContentSlot = "note" | "template" | "adsSmall" | "adsLarge";

enum DisplayRendererState {
	Loading,
	Offline,
	Rendered,
	Error,
}

const SMS_PHONE_NUMBER = import.meta.env.VITE_APP_CONTENT_SMS_NUMBER;

@Component({
	components: {
		AbsoluteLayout,
		CenteredCardLayout,
		LoadingSpinner,
		VOffline,
		SdNoteBoard,
		SdServiceModules,
		SdSlider,
	},
})
export default class Display extends Vue {
	states = DisplayRendererState;

	@Prop({ required: true })
	displayId!: string;

	@Prop({ default: null, type: [String] })
	orientationOverride!: DisplayOrientationEnum | null;

	isOnline: boolean = navigator.onLine || false;

	state: DisplayRendererState = DisplayRendererState.Loading;
	errorRetryCountdown = 10;
	errorRetryCountdownTimeout: any;

	manifest: any = false;
	adsSmall: any[] | boolean = false;
	adsLarge: any[] | boolean = false;
	templates: any[] | boolean = false;

	content: { [slot: string]: any } = {};
	contentLoaded: { [slot: string]: boolean } = {};
	transitTimes: any = null;
	weather: any = null;

	private _transitTimesRefreshTimeout: number | undefined;
	private _weatherRefreshTimeout: number | undefined;

	get assetsBasePath() {
		return `${API_PATH}/assets`;
	}

	get city() {
		return this.manifest.display?.location?.city ?? this.manifest.organization.city;
	}

	get orientation() {
		if (this.orientationOverride !== null) return this.orientationOverride;

		return this.manifest.orientation || DisplayOrientationEnum.Horizontal;
	}

	get displayRendererStyles() {
		return {
			...orientationSizeStyle(this.orientation),
		};
	}

	get bannerFallbackText() {
		return this.manifest.display.config.bannerFallbackText ?? this.manifest.organization.config.bannerFallbackText;
	}

	get bannerTemplateVariables() {
		return {
			sms: {
				codeWord: this.manifest.codeWord,
				number: SMS_PHONE_NUMBER,
				numberNoSpace: SMS_PHONE_NUMBER.replace(/\s/g, ""),
			},

			organization: {
				name: this.manifest.organization.name,
				codeWord: this.manifest.organization.codeWord,
				street: this.manifest.organization.street,
				city: this.manifest.organization.city,
				zip: this.manifest.organization.zip,
			},

			entity: {
				name: this.manifest.display.name,
				street: this.manifest.display.street,
				city: this.manifest.display.city,
				zip: this.manifest.display.zip,
			},
		};
	}

	get smsNumber() {
		return SMS_PHONE_NUMBER;
	}

	created() {
		this.setup();
	}

	async setup() {
		if (!this.displayId) return;
		this.state = DisplayRendererState.Loading;
		this._clearErrorRetryCountdown();

		if (!this.isOnline) {
			this.state = DisplayRendererState.Offline;
			return;
		}

		await this._fetchManifest();
		this._updateContent();
		this._updateTransitTimes();
		this._updateWeather();
	}

	async onNetworkConditionDetected(online: boolean) {
		if (online === this.isOnline) return;
		this.isOnline = online;
		if (online) await this.setup();
	}

	private async _fetchManifest() {
		if (this.manifest) return;
		try {
			const manifest = await http.get(`display/${this.displayId}/manifest`);
			if (manifest.data) this.manifest = manifest.data;
			this.$forceUpdate();
		} catch (err) {
			this.state = DisplayRendererState.Error;
			this._tickErrorRetryCountdown();
			throw err;
		}
	}

	private async _updateContent() {
		await Promise.all([
			// this._updateContentSlot("note"),
			this._updateContentSlot("template"),
			this._updateContentSlot("adsSmall"),
			this._updateContentSlot("adsLarge"),
		]);
		this.$forceUpdate();
	}

	private async _updateContentSlot(slot: ContentSlot) {
		this.content[slot] = await ContentService.getContent(slot, this.displayId);
		this.contentLoaded[slot] = true;
	}

	private async _updateWeather() {
		// Clear any previously set timeout
		clearTimeout(this._weatherRefreshTimeout);

		// Get new weather data
		this.weather = await WeatherService.getWeather(this.city);

		// Set timeout to refresh weather again in x minutes
		this._weatherRefreshTimeout = window.setTimeout(
			this._updateWeather.bind(this),
			1000 * 60 * 15, // 15 minutes
		);
	}

	private async _updateTransitTimes() {
		clearTimeout(this._transitTimesRefreshTimeout);

		const departures = await TransitTimesService.getDepartures(this.manifest.transitStops);

		if (departures && departures instanceof Array) {
			this.transitTimes = departures.map((departure) => {
				return {
					...departure,
					label: this._getLabelForTransitStop(departure.stopId),
				};
			});
		}

		this._transitTimesRefreshTimeout = window.setTimeout(
			this._updateTransitTimes.bind(this),
			1000 * 60 * 1, // One minute
		);
	}

	private _getLabelForTransitStop(id: string) {
		for (const stop of this.manifest.transitStops) {
			if (stop.stopId === id) return stop.label;
		}

		return "";
	}

	private _clearErrorRetryCountdown() {
		if (this.errorRetryCountdownTimeout) {
			clearTimeout(this.errorRetryCountdownTimeout);
		}
	}

	private _tickErrorRetryCountdown() {
		this._clearErrorRetryCountdown();

		if (this.errorRetryCountdown === 0) {
			this.state = DisplayRendererState.Loading;
			this.errorRetryCountdown = 30; // tslint:disable-line
			void this.setup();
		} else {
			this.errorRetryCountdownTimeout = setTimeout(() => {
				this.errorRetryCountdown -= 1;
				this._tickErrorRetryCountdown();
			}, 1000); // tslint:disable-line
		}
	}
}
</script>

<style lang="scss" scoped>
.display-renderer {
	background: lighten(#e0d6d0, 10%); // rgba(0, 0, 0, 0.2);
	overflow: hidden;
}

.state {
	padding: 20px 30px;
	max-width: 600px;
	box-sizing: border-box;

	> * {
		max-width: 520px;
		margin: 0;

		&:not(:first-child) {
			margin: 2rem 0 0;
		}
	}

	h1 {
		font-size: 3.8rem;
	}

	p {
		font-size: 1.8rem;
	}

	&-loading p {
		text-align: center;
	}
}
</style>
