import http from "@/http";

declare const ScrinzAndroidApp: undefined | {
	loadUrlWithAjax: (url: string) => void;
};

async function isOnline() {
	try {
		const res = await http.get("status");

		if (res.data && res.data.online) {
			return true;
		}
	} catch (e) {
		// noop
	}

	return false;
}

class Reload {
	isReloading = false;

	async reload() {
		if (this.isReloading) return;

		this.isReloading = true;
		await this._waitForOnline();
	}

	private async _waitForOnline() {
		if (!await isOnline()) {
			return setTimeout(
				async () => {
					await this._waitForOnline();
				},
				2000, // tslint:disable-line
			);
		}

		await this._reload();

		return;
	}

	private async _reload() {
		if (typeof ScrinzAndroidApp !== "undefined") {
			ScrinzAndroidApp.loadUrlWithAjax(window.location.href)
		} else {
			window.location.reload(true);
		}

		this.isReloading = false;
	}
}

export default new Reload();
