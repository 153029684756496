<template>
	<div id="debug">
		<router-view />
	</div>
</template>

<script lang="ts">
	import Vue from "vue";
	import { Component } from "vue-property-decorator";

	@Component
	export default class DebugView extends Vue {}
</script>

<style lang="scss">
#debug {
	background: white;
	min-height: 100%;
	font-size: 3rem;
}
</style>
