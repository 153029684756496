<template>
	<div class="sd-note-board" :style="noteBoardStyles">
		<sd-note-board-notes :display-id="displayId" :manifest="manifest" :orientation="orientation" />
		<sd-note-board-info :manifest="manifest" :sms-number="smsNumber" />
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { DisplayOrientationEnum } from "@scrinz/dtos";

import SdNoteBoardInfo from "./SdNoteBoardInfo.vue";
import SdNoteBoardNotes from "./SdNoteBoardNotes.vue";

@Component({
	name: "sd-note-board",
	components: { SdNoteBoardInfo, SdNoteBoardNotes },
})
export default class NoteBoard extends Vue {
	@Prop({ required: true })
	displayId!: string;

	@Prop({ required: true })
	manifest!: any;

	@Prop()
	smsNumber!: string;

	@Prop({ default: DisplayOrientationEnum.Horizontal, type: [String] })
	orientation!: DisplayOrientationEnum;

	get noteBoardStyles() {
		return {
			background:
				this.manifest.display.config.noteBoardBackgroundColor ?? this.manifest.organization.noteBoardBackgroundColor,
		};
	}
}
</script>

<style lang="scss">
.sd-note-board {
	background: #e0d6d0;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: 1.6rem;

	.sd-note-board-notes {
		flex: 1;
		margin: 30px 50px 0;
	}

	.sd-note-board-info {
		margin: 30px 50px;
	}
}
</style>
