<template>
	<sd-note-board
		:display-id="displayId"
		:manifest="manifest"
		:orientation="orientation"
		:class="{
			horizontal: orientation === DisplayOrientationEnum.Horizontal,
			vertical: orientation === DisplayOrientationEnum.Vertical,
		}"
	/>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

import SdNoteBoard  from "@/components/SdNoteBoard";
import { DisplayOrientationEnum } from "@scrinz/dtos";

@Component({
	components: { SdNoteBoard },
})
export default class DebugNotesView extends Vue {
	DisplayOrientationEnum = DisplayOrientationEnum;

	@Getter displayId!: string;

	@Prop()
	orientation!: string;

	manifest = {
		codeWord: "Dummy",
		displayInformation: "Hubba Bubba",
		organization: {
			name: "Dummy",
		},
	};
}
</script>

<style lang="scss" scoped>
.note-board {
	width: 830px;
	height: 1080px;

	&.vertical {
		width: 1080px;
		height: 830px;
	}
}
</style>
