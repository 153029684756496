import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// tslint:disable:object-literal-key-quotes max-line-length quotemark
const messages: VueI18n.LocaleMessages = {
	en: {
		now: "Now",
		tomorrow: "Tomorrow",
		departureTimes: "Departure times",
		fromNow: "now | 1 min | {count} min",
	},
	no: {
		now: "Nå",
		tomorrow: "I morgen",
		departureTimes: "Avgangstider",
		fromNow: "nå | 1 min | {count} min",
	},
};

export default new VueI18n({
	messages,
	locale: "no",
	fallbackLocale: "en",
});
