import Vue from "vue";
import VueRouter, { NavigationGuard } from "vue-router";

import store from "./store";

import DebugView from "./views/Debug.vue";
import DebugMainView from "./views/Debug/Main.vue";
import DebugNotesView from "./views/Debug/Notes.vue";

import Display from "./views/Display.vue";
import Embed from "./views/Embed.vue";
import Preview from "./views/Preview.vue";
import Setup from "./views/Setup.vue";

const isDebugGuard: NavigationGuard = (_to, _from, next) => {
	next(import.meta.env.MODE === "development" ? undefined : "/");
};

const isInstalledGuard: NavigationGuard = (_to, _from, next) => {
	next(store.getters.installed ? undefined : "/setup");
};

const isUninstalledGuard: NavigationGuard = (_to, _from, next) => {
	next(!store.getters.installed ? undefined : "/");
};

Vue.use(VueRouter);

export const router = new VueRouter({
	base: import.meta.env.BASE_URL,
	mode: "history",
	routes: [
		{ path: "/", beforeEnter: isInstalledGuard, component: Display },
		{
			path: "/debug",
			beforeEnter: isDebugGuard,
			component: DebugView,
			children: [
				{ path: "", name: "debug", component: DebugMainView },
				{ path: "notes", component: DebugNotesView },
			],
		},
		{ path: "/embed/:displayId", component: Embed, props: true },
		{ path: "/preview/:displayId", component: Preview, props: true },
		{ path: "/setup", beforeEnter: isUninstalledGuard, component: Setup },
		{ path: "*", redirect: "/" },
	],
});

// function isDebugRoute(route: Route): boolean {
// 	return route.matched.reduce(
// 		(prev, curr) => {
// 			return prev || curr.name === "debug";
// 		},
// 		false,
// 	);
// }

// const forceDebugIfFlaggedGuard: NavigationGuard = (to, _from, next) => {
// 	if (!isDebugRoute(to)) {
// 		next("/debug");
// 	} else {
// 		next();
// 	}
// };

// router.beforeEach(forceDebugIfFlaggedGuard);

export default router;
