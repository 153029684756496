<template>
	<div class="preview-options">
		<div class="preview-options--wrapper">
			<div class="preview-options--content">
				<span>
					Zoom:
					<input type="text" v-model.number="scale">
					%
				</span>

				<span>
					<select v-model="orientation">
						<option :value="null">Bruk default</option>
						<option :value="0">Horisontal</option>
						<option :value="1">Vertikal</option>
					</select>
				</span>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue";
	import { Component, Prop, Watch } from "vue-property-decorator";

	@Component
	export default class PreviewOptions extends Vue {
		@Prop({ default: null, type: [Number] })
		orientationOverride!: number | null;

		@Prop({ default: 100, type: [Number, String] })
		scalePercentage!: number | string | null;

		get scale() {
			return this.scalePercentage as number;
		}

		set scale(value: number) {
			this.$emit("update:scalePercentage", value);
		}

		get orientation() {
			return this.orientationOverride;
		}

		set orientation(value: number | null) {
			this.$emit("update:orientationOverride", value);
		}

		@Watch("orientationOverride", { immediate: true })
		onOrientationOverrideChange() {
			this.orientation = this.orientationOverride;
		}

		@Watch("scalePercentage", { immediate: true })
		onScalePercentageChange() {
			let value = (typeof this.scalePercentage === "string") ? parseInt(this.scalePercentage, 10) : this.scalePercentage;

			if (!value || isNaN(value)) {
				value = 100;
			}

			this.scale = value;
		}
	}
</script>

<style lang="scss" scoped>
	.preview-options {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 20px 0;
		pointer-events: none;
		overflow: hidden;
		z-index: 400;

		&--wrapper {
			margin: 0 auto;
			max-width: 400px;
			padding: 20px;
			pointer-events: all;
		}

		&--content {
			display: flex;
			justify-content: space-between;
			background: rgba(0, 0, 0, 0.7);
			border-radius: 2px;
			color: white;
			padding: 20px;
			transform: translate(0, 200%);
			transition: .3s ease all;
		}

		&--wrapper:hover {
			.preview-options--content {
				transform: translate(0, 0);
			}
		}
	}
</style>
